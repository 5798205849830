import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import SEO from '../components/SEO/SEO';
import Layout from '../components/Layout';
import Button from '@cof/graffiti-alley-spray-cans/molecules/Button';
import Link from '@cof/graffiti-alley-spray-cans/atoms/Link';
import signInIcon from '../assets/images/sign-in-refresh-icon.svg';
import mobileIcon from '../assets/images/mobile-icon.svg';
import creditScoreIcon from '../assets/images/credit-score-icon.svg';
import { superScript } from '../lib/utils';
import newrelicPageAction from '../lib/newrelicSpa';
import './sign-in.scss';

const SignIn = injectIntl(({ intl }) => {
  return (
    <Layout pageName="sign-in-page">
      <div className="page-content-wrapper">
        <section className="page-content sign-in-content">
          <div className="sign-in-icon">
            <img src={signInIcon} alt="" />
          </div>
          <h1>
            <FormattedMessage id="pages.sign-in.heading" />
          </h1>
          <div className="intro-text">
            <p>
              <FormattedMessage id="pages.sign-in.intro" />
            </p>
          </div>
          <div className="reskin-sign-in">
            <Button
              url={intl.formatMessage({ id: 'pages.sign-in.sign-in-button-link' })}
              className="reskin-button"
              onClick={() => newrelicPageAction('EaseLogin', {})}
            >
              <FormattedMessage id="pages.sign-in.sign-in-button-text" />
            </Button>
          </div>
          <Link to={intl.formatMessage({ id: 'pages.sign-in.sign-up-button-link' })} className="sign-up-link">
            <FormattedMessage id="pages.sign-in.sign-up-button-text" />
          </Link>
          <div className="app-creditkeeper-cards">
            <Link to={intl.formatMessage({ id: 'pages.sign-in.mobile-app-link' })} className="mobile-app-link">
              <div className="mobile-app">
                <div>
                  <img src={mobileIcon} alt="" />
                </div>
                <div>
                  <p className="sign-in-subhead">
                    <FormattedMessage id="pages.sign-in.mobile-app-header" />
                  </p>
                  <p className="sign-in-mobile-app-tile">
                    <FormattedMessage id="pages.sign-in.mobile-app-text" />
                  </p>
                </div>
                <span className="chevron"></span>
              </div>
            </Link>
            <Link to={intl.formatMessage({ id: 'pages.sign-in.credit-keeper-link' })} className="credit-keeper-link">
              <div className="credit-keeper">
                <div>
                  <img src={creditScoreIcon} alt="" />
                </div>
                <div>
                  <p className="sign-in-subhead">
                    <FormattedMessage id="pages.sign-in.credit-keeper-header" />
                  </p>
                  <p className="sign-in-credit-keeper-tile" data-testid="sign-in-credit-keeper-tile">
                    <FormattedMessage
                      id="pages.sign-in.credit-keeper-text"
                      values={{
                        rball: superScript('rball'),
                        MD: <sup>MD</sup>,
                        MC: <sup>MC</sup>,
                      }}
                    />
                  </p>
                </div>
                <span className="chevron"></span>
              </div>
            </Link>
          </div>
          <p className="sign-in-footnote">
            <FormattedMessage id="pages.sign-in.footnote" />
          </p>
        </section>
      </div>
    </Layout>
  );
});

export default SignIn;

/* eslint-disable react/prop-types */
export const Head = ({ pageContext }) => (
  <SEO titleKey="pages.sign-in.page-title" descriptionKey="pages.sign-in.page-desc" intl={pageContext.intl} />
);
/* eslint-enable react/prop-types */
